import React from 'react';
import Hero from '../components/Hero';
import { Seo } from '../components/seo';
import Layout from '../components/Layout';
import FAQAccord from '../components/GFlush';
import Document from '../components/Document';
import ImgContent from '../components/GSTTemp/ImgContent';
import Card from '../components/HomeComponents/Card';
import CtaWithForm from '../components/CtaWithForm';
import ContentForm from '../components/GSTTemp/ContentForm';

export default function AffiliateProgram() {
  const affiliateSlider = [
    {
      header_color: false,
      content_color: false,
      marginTop: 30,
      background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
      header: `You can earn money forever with Todayfilings.com's affiliate marketing plan.`,
      content: `RECOMMEND, GET PAID, REPEAT!`,
      image: '/imgs/business/sliders/sole-sliders.png',
      alt_tag: 'TodayFilings Affiliate Program',
    },
  ];
  /* Img Content Component Data */
  const AfIcData = {
    id: 'affpro',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: `Exclusive Features of the TodayFilings.com Affiliate Programme`,
    image: '/imgs/assited/post-incorpration.png',
    alt_tag: 'Features of the TodayFilings.com Affiliate Programme',
    points: [
      {
        head: '',
        content: `We reward affiliates with top-notch commission rates for promoting
         TodayFilings.com. When your customer or lead uses our services, the commission rates,
          which are benchmarks for the industry, will help you maximize your earnings.
           On the same, you will be eligible to collect commissions. There are no restrictions 
           on the amount you can make. Your commission increases in direct proportion to the
            amount of consumers you refer to us.`,
        icon: true,
      },
      {
        head: '',
        content: `Dependable, in-demand service for the entire business community.`,
        icon: true,
      },
      {
        head: '',
        content: `We provide high-quality services in a timely manner (TAT).`,
        icon: true,
      },
      {
        head: '',
        content: `For each customer, we offer a Single Point of Contact (SPOC).`,
        icon: true,
      },
    ],
  };
  /* Document Section Data */
  const AFStepsData = {
    id: 'af-step',
    heading: `Affiliate Program Process`,
    sub_heading: `Steps for affiliate program`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/it/form.png`,
        alt_tag: `Sign Up`,
        header: `Sign Up`,
        description: `By using this affiliate programme, you can make money 
        with TodayFilings.com along with thousands of other affiliates.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/it/jobs.png`,
        alt_tag: `Simply Suggest Leads`,
        header: `Simply Suggest Leads`,
        description: `You can simply recommend your known connections or leads who 
        require our range of services using our specially designed portal. Our tax 
        specialist will get in touch with those leads to arrange everything in detail.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/it/employed.png`,
        alt_tag: `Best Practices`,
        header: `Code of Best Practices`,
        description: `Because we value the effort put forth by our affiliates, 
        we also provide worthy people with full-time positions as a reward.`,
      },
    ],
  };
  /* Card Component Data */
  const AfCardData = {
    animations: false,
    section_head: true,
    header: 'Why you ought to pick our Affiliate Programme?',
    cards: [
      {
        each_card_link: '',
        card_img_class: '',
        card_img: '/imgs/icons/speed.png',
        card_img_alt: 'Strongly Growing Company',
        card_head: 'Rapidly Expanding',
        card_content: ``,
      },
      {
        each_card_link: '',
        card_img_class: 'bg-f78acb',
        card_img: '/imgs/icons/it/budgeting.png',
        card_img_alt: 'Exorbitant Profit Charges',
        card_head: 'Exorbitant Profit',
        card_content: ``,
      },
      {
        each_card_link: '',
        card_img_class: 'bg-eb6b3d',
        card_img: '/imgs/icons/encryption.png',
        card_img_alt: 'Secure Payments',
        card_head: 'Secure Payments',
        card_content: ``,
      },
      {
        each_card_link: '',
        card_img_class: 'bg-c679e3',
        card_img: '/imgs/icons/tax-benifits.png',
        card_img_alt: 'Direct payments',
        card_head: 'Direct payments',
        card_content: ``,
      },
    ],
  };

  const AfCfData = {
    id: 'af-benefits',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Benefits of Affiliate Programme',
    content: ``,
    body: [
      {
        head: 'Timely payment',
        points: `We respect the collaboration and relationship we have with our affiliates
         and are aware of the effort you put forth to generate income. Every time our affiliates 
         deliver us results, we think they should be compensated. As a result, we priorities paying 
         on time, every time!`,
        icon: true,
      },
      {
        head: 'Get Complete Assistance to Succeed',
        points: `Our knowledgeable business development executives provide committed support. 
        If you have any questions, our team is always available to help.`,
        icon: true,
      },
      {
        head: 'Opportunity for a Full-Time Job',
        points: `Because we value the effort put forth by our affiliates, we also provide 
        worthy people with full-time positions as a reward.`,
        icon: true,
      },
    ],
  };
  /* FaQ data */
  var APFAQ = [
    {
      header: 'How can I sign up for the affiliate programme?',
      body: [
        {
          content: `It's simple to sign up for the TodayFilings.com Affiliate Programme.
           Simply register, receive email approval, and begin working with us.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can I be compensated for mentioning myself?',
      body: [
        {
          content: `No, Only when you persuade or refer others, and they pay, will you be compensated.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Is the TodayFilings.com Affiliate Programme available to me?',
      body: [
        {
          content: `Although we don't require you to have a certain degree of education, you 
          must have strong written and verbal communication skills to become a TodayFilings.com 
          Associate. Although it is not a requirement, we would welcome some past knowledge of financial,
           tax, and loan-related services.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How can I profit from this programme?',
      body: [
        {
          content: `Through the leads you send to TodayFilings.com, you profit from qualifying purchases.`,
          icon: false,
        },
      ],
    },
    {
      header: `If a consumer makes a fraudulent payment or fails on a payment after making 
      one, would I still get paid?`,
      body: [
        {
          content: `No. In the event that a customer commits fraud or fails to make a payment, we shall not 
          pay any commission. Furthermore, we have no wish to work with any such clients.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `When will I get paid for my commission?`,
      body: [
        {
          content: `As soon as we get payment and after their services have
           been completed, we believe in paying promptly each and every time.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `For questions regarding the affiliate programme, who should an affiliate get in touch with?`,
      body: [
        {
          content: `Send us an email at affiliates@todayfilings.com, and we'll be pleased to help and get 
          back to you as quickly as we can.`,
          icon: false,
        },
      ],
    },
    {
      header: `How can I enrol in the programme?`,
      body: [
        {
          content: `sign up then the event that you satisfy the requirements, we will analyze 
          your application and approve it.`,
          icon: false,
        },
      ],
    },
    {
      header: `How will I be paid?`,
      body: [
        {
          content: `A bank transfer will be used to pay you..`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='TodayFilings Affiliate Program'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          <Hero sliders={affiliateSlider} />
          <ImgContent item={AfIcData} />
          <section></section>
          <Document docSecData={AFStepsData} />
          <Card data={AfCardData} />
          <CtaWithForm />
          <ContentForm CFSection_data={AfCfData} />

          <FAQAccord items={APFAQ} />
        </main>
      </Layout>
    </div>
  );
}
