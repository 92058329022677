import React from "react";
import { Link } from "gatsby";
import Form from "./GSTTemp/Form";

export default function CtaWithForm() {
    return (
      <section id='cta-with-form' className='cta-with-form mt-5'>
        <div className='container' data-aos='zoom-out'>
          <div className='row g-5'>
            <div className='col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first'>
              <h3>
                Schedule a call to connect with our team. We’d love to connect
                with you!
              </h3>
              <p>or</p>
              <Link className='cta-btn align-self-start' to='tel:9944246132'>
                Call Us
              </Link>
            </div>

            <Form />
          </div>
        </div>
      </section>
    );
}
